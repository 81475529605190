import { FillProperty } from 'csstype'
import React from 'react'
import { colors } from '../../../__styling/settings/colors'

export interface PerthNowWannerooJoondalupProps {
    className?: string
    width?: number
    height?: number
    fill?: FillProperty
    title?: string
}

const PerthNowWannerooJoondalupLogo: React.FC<PerthNowWannerooJoondalupProps> =
    ({ className, title, width = 130, height = 40, fill = colors.black }) => {
        return (
            <svg
                width={width}
                height={height}
                className={className}
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 132 74"
                preserveAspectRatio="xMinYMid meet"
            >
                <title>{title || 'Perth Now Wanneroo Joondalup'}</title>
                <g clip-path="url(#clip0)">
                    <path
                        d="M4.91645 45.5899H10.4713L13.5322 33.5194H13.6172L16.5647 45.5899H22.0913L26.796 26H22.0063L19.2572 38.3532H19.1438L16.0263 26H11.605L8.45911 38.3532H8.34575L5.76669 26H0.240126L4.91645 45.5899Z"
                        fill="black"
                    />
                    <path
                        d="M30.7708 45.8726C32.6414 45.8726 33.9734 45.3355 34.5969 44.4309H34.767L35.1071 45.5899H39.2449V34.8762C39.2449 31.484 36.9492 30.2685 33.2365 30.2685C30.8842 30.2685 28.9003 30.5512 27.3415 31.1448V34.4239C28.3902 34.1695 29.8356 33.9716 31.3377 33.9716C33.5199 33.9716 34.2852 34.3109 34.2852 35.8939V36.5441L31.536 36.9115C28.2485 37.3638 26.0662 38.438 26.0662 41.6041C26.0662 44.4874 27.9934 45.8726 30.7708 45.8726ZM32.4713 42.7348C31.536 42.7348 30.8275 42.339 30.8275 41.0952C30.8275 39.9645 31.3377 39.484 32.528 39.3991L34.2852 39.2295V42.1412C33.9451 42.4804 33.2365 42.7348 32.4713 42.7348Z"
                        fill="black"
                    />
                    <path
                        d="M41.4126 45.5899H46.3723V34.6501C46.9958 34.3957 47.9311 34.1413 48.7246 34.1413C50.1133 34.1413 50.6235 34.5653 50.6235 35.7525V45.5899H55.5832V34.3957C55.5832 31.4275 53.9394 30.2685 51.1336 30.2685C49.2348 30.2685 47.6477 30.8339 46.344 31.8798H46.2022V30.5512H41.4126V45.5899Z"
                        fill="black"
                    />
                    <path
                        d="M57.7387 45.5899H62.6984V34.6501C63.322 34.3957 64.2572 34.1413 65.0508 34.1413C66.4395 34.1413 66.9497 34.5653 66.9497 35.7525V45.5899H71.9094V34.3957C71.9094 31.4275 70.2656 30.2685 67.4598 30.2685C65.5609 30.2685 63.9738 30.8339 62.6701 31.8798H62.5284V30.5512H57.7387V45.5899Z"
                        fill="black"
                    />
                    <path
                        d="M81.4053 45.8726C84.1827 45.8726 86.0533 45.1941 87.1869 44.5157V41.4345C86.0249 41.915 84.4662 42.339 82.6806 42.339C80.1299 42.339 78.7979 41.6889 78.4861 39.3426H87.8104V38.0423C87.8104 33.0953 85.9116 30.2685 80.8668 30.2685C76.3322 30.2685 73.413 33.1801 73.413 37.8444V38.2119C73.413 43.1871 76.0488 45.8726 81.4053 45.8726ZM80.8668 33.5476C82.3122 33.5476 83.0207 34.3391 83.0207 36.5441H78.4578C78.6845 34.1978 79.7331 33.5476 80.8668 33.5476Z"
                        fill="black"
                    />
                    <path
                        d="M89.3947 45.5899H94.3544V35.4133C95.2046 34.9893 96.3383 34.7349 97.6703 34.7349C98.0954 34.7349 98.6339 34.8197 98.9457 34.8762V30.5229C98.7756 30.4381 98.4639 30.3816 98.0671 30.3816C96.48 30.3816 95.1196 31.2014 94.326 32.3604H94.1843V30.5512H89.3947V45.5899Z"
                        fill="black"
                    />
                    <path
                        d="M107.436 45.8726C111.829 45.8726 115.145 43.3284 115.145 38.1553V37.7879C115.145 32.8975 112.453 30.2685 107.436 30.2685C103.043 30.2685 99.7273 32.8126 99.7273 37.9575V38.325C99.7273 43.1306 102.42 45.8726 107.436 45.8726ZM107.464 42.4521C105.934 42.4521 104.829 41.7454 104.829 38.2684V37.7031C104.829 34.6501 105.764 33.6607 107.408 33.6607C108.938 33.6607 110.044 34.3109 110.044 37.8727V38.4098C110.044 41.491 109.08 42.4521 107.464 42.4521Z"
                        fill="black"
                    />
                    <path
                        d="M123.79 45.8726C128.183 45.8726 131.499 43.3284 131.499 38.1553V37.7879C131.499 32.8975 128.806 30.2685 123.79 30.2685C119.397 30.2685 116.081 32.8126 116.081 37.9575V38.325C116.081 43.1306 118.774 45.8726 123.79 45.8726ZM123.818 42.4521C122.288 42.4521 121.183 41.7454 121.183 38.2684V37.7031C121.183 34.6501 122.118 33.6607 123.762 33.6607C125.292 33.6607 126.397 34.3109 126.397 37.8727V38.4098C126.397 41.491 125.434 42.4521 123.818 42.4521Z"
                        fill="black"
                    />
                    <path
                        d="M4.36457 68.9446C8.70079 68.9446 10.8264 66.457 10.8264 62.98V49.1286H5.29983V62.245C5.29983 64.0259 4.16618 64.7044 2.4657 64.7044C1.53043 64.7044 0.623509 64.5065 0 64.3369V68.04C0.935264 68.5488 2.18228 68.9446 4.36457 68.9446Z"
                        fill="black"
                    />
                    <path
                        d="M20.2716 69.0011C24.6645 69.0011 27.9804 66.457 27.9804 61.2839V60.9164C27.9804 56.026 25.288 53.3971 20.2716 53.3971C15.8787 53.3971 12.5627 55.9412 12.5627 61.086V61.4535C12.5627 66.2591 15.2552 69.0011 20.2716 69.0011ZM20.2999 65.5807C18.7695 65.5807 17.6642 64.874 17.6642 61.397V60.8316C17.6642 57.7786 18.5994 56.7893 20.2432 56.7893C21.7737 56.7893 22.879 57.4394 22.879 61.0012V61.5383C22.879 64.6196 21.9154 65.5807 20.2999 65.5807Z"
                        fill="black"
                    />
                    <path
                        d="M36.6254 69.0011C41.0183 69.0011 44.3343 66.457 44.3343 61.2839V60.9164C44.3343 56.026 41.6418 53.3971 36.6254 53.3971C32.2325 53.3971 28.9166 55.9412 28.9166 61.086V61.4535C28.9166 66.2591 31.609 69.0011 36.6254 69.0011ZM36.6538 65.5807C35.1233 65.5807 34.018 64.874 34.018 61.397V60.8316C34.018 57.7786 34.9533 56.7893 36.5971 56.7893C38.1275 56.7893 39.2328 57.4394 39.2328 61.0012V61.5383C39.2328 64.6196 38.2692 65.5807 36.6538 65.5807Z"
                        fill="black"
                    />
                    <path
                        d="M45.9223 68.7185H50.882V57.7787C51.5055 57.5242 52.4408 57.2698 53.2343 57.2698C54.6231 57.2698 55.1332 57.6938 55.1332 58.8811V68.7185H60.0929V57.5242C60.0929 54.5561 58.4491 53.3971 55.6433 53.3971C53.7445 53.3971 52.1574 53.9624 50.8537 55.0084H50.7119V53.6798H45.9223V68.7185Z"
                        fill="black"
                    />
                    <path
                        d="M67.6333 69.0011C69.4755 69.0011 70.8359 68.5206 71.8561 67.5595H71.9978L72.3379 68.7185H76.6175V47.8282H71.6578V54.0472C71.1476 53.7646 70.099 53.3971 68.5119 53.3971C64.5157 53.3971 61.6533 55.602 61.6533 61.2556V61.5949C61.6533 66.9093 64.459 69.0011 67.6333 69.0011ZM69.5322 65.3828C67.86 65.3828 66.783 64.563 66.783 61.397V60.9164C66.783 57.7221 67.8317 56.7893 69.5888 56.7893C70.5241 56.7893 71.261 57.0437 71.6578 57.3264V64.7044C71.2043 65.0436 70.4674 65.3828 69.5322 65.3828Z"
                        fill="black"
                    />
                    <path
                        d="M82.8475 69.0011C84.718 69.0011 86.0501 68.4641 86.6736 67.5595H86.8436L87.1837 68.7185H91.3215V58.0048C91.3215 54.6126 89.0259 53.3971 85.3132 53.3971C82.9609 53.3971 80.977 53.6798 79.4182 54.2734V57.5525C80.4668 57.2981 81.9122 57.1002 83.4143 57.1002C85.5966 57.1002 86.3618 57.4394 86.3618 59.0225V59.6726L83.6127 60.0401C80.3251 60.4924 78.1428 61.5666 78.1428 64.7326C78.1428 67.616 80.07 69.0011 82.8475 69.0011ZM84.548 65.8634C83.6127 65.8634 82.9042 65.4676 82.9042 64.2238C82.9042 63.0931 83.4143 62.6125 84.6046 62.5277L86.3618 62.3581V65.2697C86.0217 65.609 85.3132 65.8634 84.548 65.8634Z"
                        fill="black"
                    />
                    <path
                        d="M93.5742 68.7185H98.534V47.8282H93.5742V68.7185Z"
                        fill="black"
                    />
                    <path
                        d="M105.383 69.0011C107.281 69.0011 108.614 68.4075 109.946 67.3899H110.087L110.427 68.7185H114.792V53.6798H109.832V64.6761C109.209 64.9588 108.443 65.2415 107.565 65.2415C106.261 65.2415 105.751 64.7892 105.751 63.5736V53.6798H100.791V64.6196C100.791 67.7008 102.492 69.0011 105.383 69.0011Z"
                        fill="black"
                    />
                    <path
                        d="M117.036 74.2308H121.996V68.4358C122.562 68.6902 123.526 69.0011 125.028 69.0011C129.166 69.0011 132 66.9093 132 60.973V60.6337C132 55.1214 129.251 53.3971 126.332 53.3971C124.319 53.3971 122.959 53.9907 121.967 54.9518H121.825V53.6798H117.036V74.2308ZM124.064 65.5807C123.214 65.5807 122.421 65.3545 121.996 65.0436V57.8069C122.449 57.496 123.214 57.2133 124.234 57.2133C125.935 57.2133 126.899 57.9483 126.899 61.086V61.5666C126.899 64.7892 125.85 65.5807 124.064 65.5807Z"
                        fill="black"
                    />
                    <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M67.3252 6.7494V16.1452C67.3252 19.8732 70.3918 22.8949 74.1714 22.8949H125.071C128.852 22.8949 131.917 19.8732 131.917 16.1452V6.7494C131.917 3.02272 128.852 0 125.071 0H74.1714C70.3918 0 67.3252 3.02272 67.3252 6.7494Z"
                        fill="#D42626"
                    />
                    <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M103.734 4.37622H107.607L110.705 14.2447H110.764L113.654 4.37622H117.527L120.624 14.2447H120.683L123.662 4.37622H127.266L122.411 18.4746H118.866L115.47 8.84081H115.411L112.403 18.4746H108.709L103.734 4.37622"
                        fill="#FFFFFE"
                    />
                    <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M96.4497 15.694C99.071 15.694 100.65 13.6683 100.65 11.4652C100.65 9.26236 99.071 7.23568 96.4497 7.23568C93.8284 7.23568 92.2497 9.26236 92.2497 11.4652C92.2497 13.6683 93.8284 15.694 96.4497 15.694M96.45 4.06372C100.769 4.06372 104.225 7.03008 104.225 11.4652C104.225 15.8997 100.769 18.8673 96.45 18.8673C92.1309 18.8673 88.6758 15.8997 88.6758 11.4652C88.6758 7.03008 92.1309 4.06372 96.45 4.06372"
                        fill="#FFFFFE"
                    />
                    <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M74.0923 4.41634H77.4883V6.67778H77.548C78.2029 5.26826 79.5434 4.06372 81.9266 4.06372C85.7692 4.06372 87.1396 6.73671 87.1396 9.61467V18.5147H83.5653V11.3774C83.5653 9.82028 83.4461 7.23569 80.9437 7.23569C78.5907 7.23569 77.6669 8.93953 77.6669 10.8192V18.5147H74.0923V4.41634"
                        fill="#FFFFFE"
                    />
                    <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M64.5847 11.4468C64.5657 6.8661 62.7738 4.23139 58.3649 4.23139C56.9007 4.23139 55.6062 4.60982 54.3906 5.6124V0.283447H50.9512V18.5972H54.3906V10.8679C54.3906 9.22943 55.2608 7.53491 58.1298 7.53491C59.9598 7.53491 61.134 8.7636 61.134 10.3587C61.1495 10.7149 61.1524 11.0411 61.1524 11.3027V18.5972H64.5912V11.4629L64.5847 11.4468"
                        fill="black"
                    />
                    <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M49.0242 15.1994V15.1911L49.0097 15.1994C47.4429 15.1208 45.9324 13.9723 45.4228 12.7265C45.3076 12.4446 45.2417 11.9788 45.2417 11.4449V7.43773H49.0242V4.54177H45.2417V0.593506H41.8022V10.7454C41.8022 11.2216 41.8495 12.2245 41.94 12.6752C42.6058 16.0028 45.611 18.5133 49.2163 18.5133V15.2082C49.1519 15.2082 49.0886 15.2019 49.0242 15.1994"
                        fill="black"
                    />
                    <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M39.68 4.18715C39.5462 4.171 39.4113 4.15991 39.2716 4.15991L39.2066 4.16403C39.1778 4.16403 39.1497 4.15991 39.1203 4.15991C37.6563 4.15991 36.0534 4.50255 35.145 5.79141V4.50065H31.7065V18.5257H35.145V11.2844L35.1463 11.2353C35.1463 9.28651 36.3095 7.46375 39.1203 7.46375C39.6128 7.46375 40.0826 7.554 40.5189 7.71234V4.29291C40.2443 4.24161 39.9643 4.20931 39.68 4.18715"
                        fill="black"
                    />
                    <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M23.1898 7.46407C24.915 7.46407 26.3838 8.52556 26.9558 10.0136H19.4231C19.9958 8.52556 21.4633 7.46407 23.1898 7.46407M26.5277 13.5837C25.8049 14.6294 24.58 15.3178 23.1894 15.3178C21.3637 15.3178 19.8244 14.1325 19.3306 12.5096H27.047L27.046 12.5121H30.5417C30.5799 12.1362 30.6025 11.7724 30.6025 11.3914C30.6025 7.39725 27.2837 4.15991 23.1885 4.15991C19.0942 4.15991 15.7744 7.39725 15.7744 11.3914C15.7744 15.3856 19.0942 18.6226 23.1885 18.6226C25.6393 18.6226 27.8118 17.4636 29.1616 15.6766L26.5277 13.5837"
                        fill="black"
                    />
                    <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M7.43077 15.3175C5.20691 15.3175 3.40441 13.5597 3.40441 11.3915C3.40441 9.22225 5.20691 7.46346 7.43077 7.46346C9.21225 7.46346 10.7195 8.59334 11.2508 10.1561C11.353 10.5298 11.4064 10.9199 11.4064 11.3161C11.4064 12.1546 11.1684 12.9634 10.7286 13.6401C10.0003 14.6538 8.79607 15.3175 7.43077 15.3175M7.43022 4.15991C3.33595 4.15991 0.0164922 7.39693 0.0164922 11.3914C0.0164922 11.4259 0.0216662 11.4586 0.0216662 11.4921L0 11.5254V22.6339H3.43943V17.4779C4.59259 18.1992 5.9595 18.6223 7.43022 18.6223C11.5251 18.6223 14.8443 15.385 14.8443 11.3914C14.8443 7.39693 11.5251 4.15991 7.43022 4.15991"
                        fill="black"
                    />
                </g>
                <defs>
                    <clipPath id="clip0">
                        <rect width="132" height="74" fill="white" />
                    </clipPath>
                </defs>
            </svg>
        )
    }

export default PerthNowWannerooJoondalupLogo
